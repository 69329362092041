import './App.css';
import React, {Component} from 'react';
import axios from 'axios'; // npm install axios

class App extends Component{
  state = {
    selectedFile: null,
    fileUploadedSuccessfully: false
  }
  onFileChange = event => {
    this.setState({selectedFile: event.target.files[0]});
  }

  onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      "demo file",
      this.state.selectedFile,
      this.state.selectedFile.name
    )
    axios.post("https://40jztv2qy9.execute-api.us-east-1.amazonaws.com/prod/file-upload", formData).then(() => 
    {
      this.setState({selectedFile: null});
      this.setState({fileUploadedSuccessfully: true});
    })
  }

  fileData = () => {
    if (this.state.selectedFile){
      return(
      <div>
        <h2>File info</h2>
        <p>File Name: {this.state.selectedFile.name}</p>
        <p>File Type: {this.state.selectedFile.type}</p>
        <p>Last Modified: {" "}
          {this.state.selectedFile.lastModifiedDate.toDateString()}
        </p>
      </div>
      );
    } else if (this.state.fileUploadedSuccessfully){
      return(
      <div>
        <br />
        <h4>File Upload Successfuly!</h4> 
      </div>
      );
    } else{
      return(
      <div>
        <br/>
        <h4> Please choose the file and press the upload button.</h4>
      </div>
      );
    }
  }

  render(){
    return (
      <div className='container'>
        <h2>MinGyu's Personal website</h2>
        <h2>This is test uploading</h2>
        <h3>test test</h3>

        <h3>This Website will keep being added more functions depends on my Knoweldge</h3>
        <div>
          <input type = "file" onChange = {this.onFileChange} />
          <button onclick= {this.onFileUpload}>Upload</button>
        </div>
        {this.fileData()}
      </div>
    )
  }
}

export default App;